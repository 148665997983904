import React, {FC} from 'react';
import {Subtitle} from '../index';

import {ModalPageHeaderProps} from './types';

import './ModalPageHeader.css';

const ModalPageHeader: FC<ModalPageHeaderProps> = ({children, before, after}) => {
    return (
        <div className={'ModalPageHeader'}>
            <div className={'ModalPageHeader__before'}>{before}</div>
            <Subtitle>{children}</Subtitle>
            <div className={'ModalPageHeader__after'}>{after}</div>
        </div>
    );
};

export default ModalPageHeader;