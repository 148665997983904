import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from '../../../models/User';
import {fetchUser} from './api';

interface UserState {
    status: 'init' | 'loading' | 'success' | 'error',
    error: string | null,
    data: User | null,
}

const initialState: UserState = {
    status: 'init',
    error: null,
    data: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state) => {
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.fulfilled.type, (state, action: PayloadAction<User>) => {
                state.status = 'success';
                state.error = null;
                state.data = action.payload;
            }).addCase(fetchUser.pending.type, (state) => {
                state.status = 'loading';
                state.error = null;
            }).addCase(fetchUser.rejected.type, (state, action: PayloadAction<string>) => {
                state.status = 'error';
                state.error = action.payload;
            });
    },
});

export const {logout} = userSlice.actions;

export default userSlice.reducer;