import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from '../../../models/User';

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (_, {rejectWithValue}) => {
        try {
            const response = await axios.get<User[]>('https://jsonplaceholder.typicode.com/users?_limit=1');
            return response.data[0];
        }
        catch (error) {
            console.error(error);
            return rejectWithValue(error);
        }
    },
);