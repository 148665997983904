import {useSearchParams} from 'react-router-dom';

export const useModal = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const activeModal = searchParams.get('modal') || null;
    
    const closeModal = (state?: any) => {
        searchParams.delete('modal');
        setSearchParams(searchParams, {state});
    };
    
    const openModal = (modalId: string, state?: any) => {
        searchParams.set('modal', modalId);
        setSearchParams(searchParams, {state});
    };
    
    return {
        activeModal,
        openModal,
        closeModal,
    };
};