import React, {FC, InputHTMLAttributes, useState} from 'react';

import FormField from '../FormField/FormField';
import {IconButton} from '../../index';
import {IconHide, IconView} from '../../../assets/icons';

import './Input.css';


const Input: FC<InputHTMLAttributes<HTMLInputElement>> = ({type, ...props}) => {
    const [showPassword, setShowPassword] = useState(false);
    const isPassword = type === 'password';
    
    const toggleShowPassword = () => {
        setShowPassword(prev => !prev);
    };
    
    const getType = () => {
        if (!isPassword) return type;
        
        return showPassword ? 'text' : 'password';
    };
    
    const getAfter = () => {
        switch (type) {
            case 'password':
                if (showPassword) {
                    return <IconButton onClick={toggleShowPassword}><IconHide/></IconButton>;
                }
                return <IconButton onClick={toggleShowPassword}><IconView/></IconButton>;
            default:
                return null;
        }
    };
    
    return (
        <FormField className={'Input'} after={getAfter()}>
            <input type={getType()} className={'input_el'} {...props}/>
        </FormField>
    );
};

export default Input;