import React, {FC} from 'react';
import {PlaceholderProps} from './types';

import './Placeholder.css';
import {Body, Subtitle} from '../index';

const Placeholder: FC<PlaceholderProps> = ({children, icon, header, actions}) => {
    return (
        <div className={'Placeholder'}>
            {icon && <div className={'Placeholder__icon'}>{icon}</div>}
            {header && <Subtitle>{header}</Subtitle>}
            {children && <Body>{children}</Body>}
            {actions && <span className={'Placeholder__actions'}>{actions}</span>}
        </div>
    );
};

export default Placeholder;