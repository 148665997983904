import React, {FC, HTMLAttributes} from 'react';

import './Container.css';

const Container: FC<HTMLAttributes<HTMLDivElement>> = ({children}) => {
    return (
        <div className={'container'}>
            {children}
        </div>
    );
};

export default Container;