import React, {FC} from 'react';
import {FormFieldProps} from './types';

import './FormField.css';
import classNames from 'classnames';

const FormField: FC<FormFieldProps> = ({children, status = 'default', after, className}) => {
    const cl = classNames(
        'FormField',
        className && className,
        `FormField__status-${status}`,
    );
    
    return (
        <div className={cl}>
            {children}
            {after && <div className={'FormField__after'}>{after}</div>}
            <div className={'FormField__border'}/>
        </div>
    );
};

export default FormField;