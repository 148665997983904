import React, {ChangeEvent, useEffect} from 'react';
import {Outlet, useNavigate, useOutletContext} from 'react-router-dom';

import {Container, Group} from '../components';

import {useForm} from '../hooks';

interface UserData {
    email: string,
    password: string,
}

interface AuthContext {
    userData: UserData;
    onUserDataChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const AuthPage = () => {
    const navigate = useNavigate();
    
    const {formData: userData, onInputChange: onUserDataChange} = useForm<UserData>({
        email: '',
        password: '',
    });
    
    useEffect(() => {
        if (!userData.email) navigate('/auth');
    }, [navigate, userData.email]);
    
    return (
        <Container>
            <Group>
                <Outlet context={{userData, onUserDataChange}}/>
            </Group>
        </Container>
    );
};

export const useAuth = () => useOutletContext<AuthContext>();

export default AuthPage;