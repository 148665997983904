import {ChangeEvent, useState} from 'react';

export const useForm = <T>(initialState: T) => {
    const [formData, setFromData] = useState(initialState);
    
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFromData(prev => ({...prev, [name]: value}));
    };
    
    const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = e.target;
        setFromData(prev => ({...prev, [name]: value}));
    };
    
    return {
        formData,
        onInputChange,
        onSelectChange,
        setFromData,
    };
};