import React, {ElementType, FC} from 'react';
import classNames from 'classnames';

import {TitleProps} from './types';
import './Title.css';

const Title: FC<TitleProps> = ({
    children,
    className,
    level = 1,
    Component,
}) => {
    const cl = classNames(
        'Title',
        `Title_level-${level}`,
        className && className,
    );
    
    Component = Component || `h${level}` as ElementType;
    
    return (
        <Component className={cl}>
            {children}
        </Component>
    );
};

export default Title;