import React from 'react';
import {Button, Container, Group, Placeholder} from '../components';
import {Link} from 'react-router-dom';

const ErrorPage = () => {
    return (
        <Container>
            <Group>
                <Placeholder
                    header={'Страница не найдена'}
                    actions={<Link to={'/'}><Button>На главную</Button></Link>}
                />
            </Group>
        </Container>
    );
};

export default ErrorPage;