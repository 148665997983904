import React, {ButtonHTMLAttributes, FC} from 'react';

import './IconButton.css';
import classNames from 'classnames';

const IconButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({children, className, ...props}) => {
    const cl = classNames('IconButton', className && className);
    
    return (
        <button type={'button'} className={cl} {...props}>
            {children}
        </button>
    );
};

export default IconButton;