import React, {FC} from 'react';
import classNames from 'classnames';

import {SubtitleProps} from './types';
import './Subtitle.css';

const Subtitle: FC<SubtitleProps> = ({
    children,
    className,
    level = 1,
    Component = 'h4',
}) => {
    const cl = classNames(
        'Subtitle',
        `Subtitle_level-${level}`,
        className && className,
    );
    return (
        <Component className={cl}>
            {children}
        </Component>
    );
};

export default Subtitle;