import React from 'react';
import {IconChevronLeft} from '../../assets/icons';
import {useNavigate} from 'react-router-dom';
import IconButton from './IconButton';

const IconButtonBack = () => {
    const navigate = useNavigate();
    
    return (
        <IconButton onClick={() => navigate(-1)}>
            <IconChevronLeft/>
        </IconButton>
    );
};

export default IconButtonBack;