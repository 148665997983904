import React, {FC, FormEvent} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button, FormItem, Header, Input} from '../../index';

import {useAuth} from '../../../pages/AuthPage';
import {AUTH_RESTORE_FINISH_PATH, AUTH_SIGNUP_FINISH_PATH} from '../../../router';
import {useForm, useValidateForm} from '../../../hooks';
import FormBottomButtons from '../../FormItems/FormBottomButtons/FormBottomButtons';
import IconButtonBack from '../../IconButton/IconButtonBack';

const ConfirmEmail: FC<{ isSignUp?: boolean }> = ({isSignUp}) => {
    const {userData: {email}} = useAuth();
    const {formData: {code}, onInputChange: onCodeChange} = useForm({
        code: '',
    });
    const {status, message, setError, setValid} = useValidateForm();
    
    const navigate = useNavigate();
    
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if (!code.trim()) {
            setError('Введите код, который мы отправили на почту');
            return;
        }
        
        isSignUp ? navigate(AUTH_SIGNUP_FINISH_PATH) : navigate(AUTH_RESTORE_FINISH_PATH);
    };
    
    return (
        <>
            <Header
                before={<IconButtonBack/>}
                subheader={<>введите код из сообщения, которое мы отправили на почту <span
                    className={'accent'}>{email}</span></>}
            >
                Подтвердите почту
            </Header>
            <form onSubmit={onSubmit}>
                <FormItem
                    status={status}
                    message={message}
                    onClick={setValid}
                >
                    <Input
                        name={'code'}
                        value={code}
                        onChange={onCodeChange}
                        placeholder={'Код'}
                    />
                </FormItem>
                <FormBottomButtons>
                    <Button type={'submit'}>Подтвердить</Button>
                    <Button mode={'link'} size={'s'}>Отправить повторно</Button>
                </FormBottomButtons>
            </form>
        </>
    );
};

export default ConfirmEmail;