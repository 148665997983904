import React from 'react';
import {NavLink} from 'react-router-dom';

import {IconBox, IconCheck, IconDocument} from '../../assets/icons';

import {Logo} from '../../assets/images/svg';

import {logout} from '../../services/store/reducers/user/userSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';

import './PageHeader.css';
import NavItem from '../NavItem/NavItem';
import Button from '../Button/Button';


const PageHeader = () => {
    const dispatch = useAppDispatch();
    const {data: userData} = useAppSelector(state => state.user);
    const isAuth = !!userData?.email;
    
    const onLogoutClick = () => {
        dispatch(logout());
    };
    
    return (
        <header className={'PageHeader'}>
            <NavLink to={'/'} className={'PageHeader__logoContainer'}>
                <Logo className={'PageHeader__logo'}/>
            </NavLink>
            <nav className={'PageHeader__nav'}>
                {
                    isAuth && <>
                        <NavItem href={'/subscription'} icon={IconCheck}>Подписка</NavItem>
                        <NavItem href={'/shop'} icon={IconBox}>Магазин</NavItem>
                        <NavItem href={'/pets'} icon={IconDocument}>Питомцы и данные</NavItem>
                    </>
                }
            </nav>
            <div className={'PageHeader__buttons'}>
                <Button mode={'outline'}>Задать вопрос</Button>
                {isAuth && <Button mode={'outline'} appearance={'neutral'} onClick={onLogoutClick}>Выйти</Button>}
            </div>
        </header>
    );
};

export default PageHeader;