import React, {Children, FC, ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

import {Modal, ModalRootProps} from './types';
import {ModalPageProps} from '../ModalPage/types';

import './ModalRoot.css';

const ModalRoot: FC<ModalRootProps> = ({children, id, activeModal, onClose}) => {
    const modals = Children.toArray(children) as ReactElement<ModalPageProps>[];
    const [activeModalId, setActiveModalId] = useState<Modal>(null);
    const [enteringModalId, setEnteringModalId] = useState<Modal>(null);
    const [exitingModalId, setExitingModalId] = useState<Modal>(null);
    
    const timeout = useRef<NodeJS.Timeout>();
    
    const historyRef = useRef<Modal[]>([]);
    const history = historyRef.current;
    const prevModal = history[history.length - 1] || null;
    
    const waitModalAnimation = (cb: () => void) => setTimeout(cb, 320);
    
    const handleOpen = useCallback((modal: Modal) => {
        setEnteringModalId(modal);
        clearTimeout(timeout.current);
        timeout.current = waitModalAnimation(() => {
            setActiveModalId(modal);
            setEnteringModalId(null);
            historyRef.current.push(modal);
        });
    }, [historyRef]);
    
    const handleClose = useCallback(() => {
        setExitingModalId(activeModalId);
        clearTimeout(timeout.current);
        timeout.current = waitModalAnimation(() => {
            historyRef.current.pop();
            setActiveModalId(historyRef.current[historyRef.current.length - 1] || null);
            setExitingModalId(null);
        });
    }, [historyRef, activeModalId]);
    
    useEffect(() => {
        if (activeModal === prevModal) {
            return;
        }
        
        if (!activeModal) {
            handleClose();
            return;
        }
        
        if (activeModal !== prevModal) {
            handleOpen(activeModal);
            return;
        }
    }, [activeModal, prevModal, handleClose, handleOpen]);
    
    const onCloseClick = () => onClose();
    
    const cl = classNames('ModalRoot', {
        'ModalRoot-active': !!activeModalId || !!enteringModalId || !!exitingModalId,
    });
    
    const getModalRootInClassname = (modalId: Modal) => {
        return classNames(
            'ModalRoot__Modal',
            {
                'ModalRoot__Modal--shown': modalId !== exitingModalId && (modalId === enteringModalId || modalId === activeModalId),
            },
        );
    };
    
    return (
        <div id={id} className={cl} onClick={onCloseClick}>
            {
                modals.map((modal) => {
                    const id = modal.props.id;
                    if (id !== activeModal && id !== activeModalId && id !== enteringModalId && id !== exitingModalId) return null;
                    return (
                        <div key={id} className={getModalRootInClassname(id)}>
                            <div className={'ModalRoot__darken'}/>
                            {modal}
                        </div>
                    );
                })
            }
        </div>
    );
};
export default ModalRoot;