import React, {FC} from 'react';
import {SimpleCellProps} from './types';
import classNames from 'classnames';
import {Subtitle} from '../index';

import './SimpleCell.css';

const SimpleCell: FC<SimpleCellProps> = ({children, className = '', after, ...props}) => {
    const cl = classNames('SimpleCell', className);
    return (
        <div className={cl} {...props}>
            <Subtitle level={2} className={'SimpleCell__text'}>{children}</Subtitle>
            {after && <div className={'SimpleCell__after'}>{after}</div>}
        </div>
    );
};

export default SimpleCell;