import React, {FC, MouseEvent, useEffect, useState} from 'react';
import {Button, Group, IconButton, ModalPage, ModalPageHeader, SimpleCell} from '../components';
import {IconCircleCheckOn, IconDismiss} from '../assets/icons';

interface SelectCityModalPageProps {
    id: string;
    closeModal?: () => any;
}

interface City {
    id: number;
    city: string;
}

const SelectCityModalPage: FC<SelectCityModalPageProps> = ({id, closeModal}) => {
    const [selectedCity, setSelectedCity] = useState<City | undefined>(undefined);
    const [cities, setCities] = useState<City[]>([]);
    
    useEffect(() => {
        const data = [
            {id: 1, city: 'Санкт-Петербург'},
            {id: 2, city: 'Москва'},
            {id: 3, city: 'Волгоград'},
        ];
        
        setCities(data);
    }, []);
    
    const onCityChange = (e: MouseEvent<HTMLElement>) => {
        const {id} = e.currentTarget.dataset;
        
        if (!id) return;
        
        const city = cities.find(city => city.id === Number(id));
        setSelectedCity(city);
    };
    
    const onClose = () => {
        closeModal && closeModal();
    };
    
    const onSaveClick = () => {
        console.log(selectedCity);
        onClose();
    };
    
    return (
        <ModalPage
            id={id}
            header={<ModalPageHeader after={<IconButton onClick={onClose}><IconDismiss fill={'var(--text_secondary)'}/></IconButton>}>Город</ModalPageHeader>}
            fullScreen
            footer={<Button size={'l'} onClick={onSaveClick} stretched>Сохранить адрес</Button>}
        >
            <Group>
                {
                    cities.map(city => (
                        <SimpleCell
                            key={city.id}
                            data-id={city.id}
                            onClick={onCityChange}
                            after={city.id === selectedCity?.id ? <IconCircleCheckOn fill={'var(--accent)'}/> : null}
                        >
                            {city.city}
                        </SimpleCell>
                    ))
                }
            </Group>
            {/* Отступ от футера */}
            <div style={{height: 68}}/>
        </ModalPage>
    );
};

export default SelectCityModalPage;