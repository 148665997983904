import React from 'react';

const HomePage = () => {
    return (
        <div>
            home page
        </div>
    );
};

export default HomePage;