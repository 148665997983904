import React, {FormEvent} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button, FormItem, Header, Input} from '../../index';
import FormSocialButtons from '../../FormItems/FormSocialButtons/FormSocialButtons';
import {useAuth} from '../../../pages/AuthPage';

import {AUTH_RESTORE_CONFIRM_PATH, AUTH_WELCOME_PATH} from '../../../router';
import {IconArrowRight} from '../../../assets/icons';
import {VALIDATE_EMAIL_REGEX} from '../../../helpers/regex';
import {useValidateForm} from '../../../hooks';

const EnterEmail = () => {
    const {status, message, setError, setValid} = useValidateForm();
    
    const navigate = useNavigate();
    const {userData: {email}, onUserDataChange: onChange} = useAuth();
    
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if (!email.trim()) {
            setError('Введите потчу, чтобы войти или зарегистрироваться');
            return;
        }
        
        if (!email.match(VALIDATE_EMAIL_REGEX)) {
            setError('Почта должна содержать символ @ и домен после него');
            return;
        }
        
        const hasAccount = email === 'test@mail.ru';
        
        if (!hasAccount) {
            navigate(AUTH_RESTORE_CONFIRM_PATH);
            return;
        }
        
        navigate(AUTH_WELCOME_PATH);
    };
    
    return (
        <>
            <Header
                subheader={'чтобы назначить встречу с ветеринаром или просмотреть профиль, если вы уже с нами'}
            >
                Войдите
            </Header>
            <form onSubmit={onSubmit}>
                <FormItem
                    status={status}
                    message={message}
                >
                    <Input
                        name={'email'}
                        value={email}
                        onChange={onChange}
                        onClick={setValid}
                        autoComplete={'email'}
                        placeholder={'Электронная почта'}
                    />
                </FormItem>
                <Button type={'submit'} after={<IconArrowRight/>}>Продолжить</Button>
            </form>
            <FormSocialButtons/>
        </>
    );
};

export default EnterEmail;