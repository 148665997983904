import React, {FC} from 'react';
import {HeaderProps} from './types';

import {Body, Title} from '../index';
import './Header.css';


const Header: FC<HeaderProps> = ({children, subheader, before}) => {
    return (
        <div className={'Header'}>
            <span className={'Header__content'}>
                 {before} <Title level={1} Component={'h2'}>{children}</Title>
            </span>
            {subheader && <Body className={'Header__subheader'}>{subheader}</Body>}
        </div>
    );
};

export default Header;