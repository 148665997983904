import React, {FC} from 'react';
import classNames from 'classnames';

import {BodyProps} from './types';
import './Body.css';

const Body: FC<BodyProps> = ({
    children,
    className,
    Component = 'span',
    level = 1,
    weight = 'regular',
}) => {
    const cl = classNames(
        'Body',
        `Body_level-${level}`,
        `Body_weight-${weight}`,
        className && className,
    );
    
    return (
        <Component className={cl}>
            {children}
        </Component>
    );
};

export default Body;