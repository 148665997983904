import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {AUTH_PATH} from '../../../router';
import {AuthRequiredProps} from './types';
import {useAppSelector} from '../../../hooks/redux';

const AuthRequired: FC<AuthRequiredProps> = ({children}) => {
    const {data: userData} = useAppSelector(state => state.user);
    const isAuth = !!userData?.email;
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!isAuth) navigate(AUTH_PATH);
    }, [isAuth, navigate]);
    
    return children;
};

export default AuthRequired;