import {useState} from 'react';
import {FormFieldProps} from '../components/FormItems/FormField/types';

export interface StatusProps {
    status: FormFieldProps['status'];
    message: string;
}

const initialStatus: StatusProps = {
    status: 'default',
    message: '',
};

export const useValidateForm = () => {
    const [status, setStatus] = useState<StatusProps>(initialStatus);
    
    const setError = (message: string) => {
        setStatus({
            status: 'error',
            message,
        });
    };
    
    const setValid = () => {
        setStatus({
            status: 'default',
            message: '',
        });
    };
    
    return {
        status: status.status,
        message: status.message,
        setError,
        setValid,
    };
};