import React, {FormEvent} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {Button, FormItem, Header, Input} from '../../index';

import {useAuth} from '../../../pages/AuthPage';
import FormBottomButtons from '../../FormItems/FormBottomButtons/FormBottomButtons';
import IconButtonBack from '../../IconButton/IconButtonBack';
import {ACCOUNT_PATH} from '../../../router';
import {useValidateForm} from '../../../hooks';
import {useAppDispatch} from '../../../hooks/redux';
import {fetchUser} from '../../../services/store/reducers/user/api';

const EnterCurrentPassword = () => {
    const {userData: {email, password}, onUserDataChange: onChange} = useAuth();
    const {status, message, setError, setValid} = useValidateForm();
    
    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();
    
    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if (!password.trim()) {
            setError('Введите пароль');
            return;
        }
        
        if (password.trim() !== 'admin') {
            setError('Неверный пароль. Повторите попытку или нажмите «Не помню пароль», чтобы сбросить его');
            return;
        }
        
        dispatch(await fetchUser());
        
        navigate(ACCOUNT_PATH);
    };
    
    return (
        <>
            <Header
                before={<IconButtonBack/>}
                subheader={<>для почты <span className={'accent'}>{email}</span></>}
            >
                Введите пароль
            </Header>
            <form onSubmit={onSubmit}>
                <FormItem hidden>
                    <Input
                        type={'email'}
                        name={'email'}
                        defaultValue={email}
                        autoComplete={'email'}
                    />
                </FormItem>
                <FormItem
                    status={status}
                    message={message}
                >
                    <Input
                        type={'password'}
                        name={'password'}
                        value={password}
                        onChange={onChange}
                        onClick={setValid}
                        autoComplete={'current-password'}
                        placeholder={'Ваш пароль'}
                    />
                </FormItem>
                <FormBottomButtons>
                    <Button type={'submit'}>Продолжить</Button>
                    <Link to={'/auth/restore/confirm-email'}>
                        <Button mode={'link'} size={'s'}>Не помню пароль</Button>
                    </Link>
                </FormBottomButtons>
            </form>
        </>
    );
};

export default EnterCurrentPassword;