import React from 'react';
import {createBrowserRouter} from 'react-router-dom';

import {AccountPage, AuthPage, ErrorPage, HomePage, PageWrapper} from './pages';
import {ConfirmEmail, EnterCurrentPassword, EnterEmail, EnterNewPassword} from './components/Forms';
import {AuthRequired} from './components';
import OnlyUnauthorized from './components/RouterHelpers/OnlyUnauthorized/OnlyUnauthorized';

export const AUTH_PATH = '/auth';
export const AUTH_WELCOME_PATH = AUTH_PATH + '/welcome';
export const AUTH_RESTORE_CONFIRM_PATH = AUTH_PATH + '/restore/confirm-email';
export const AUTH_RESTORE_FINISH_PATH = AUTH_PATH + '/restore/finish';
export const AUTH_SIGNUP_CONFIRM_PATH = AUTH_PATH + '/signup/confirm-email';
export const AUTH_SIGNUP_FINISH_PATH = AUTH_PATH + '/signup/finish';
export const ACCOUNT_PATH = '/account';

const router = createBrowserRouter([
    {
        path: '/',
        element: <PageWrapper/>,
        children: [
            {
                index: true,
                element: <AuthRequired><HomePage/></AuthRequired>,
            },
            {
                path: AUTH_PATH,
                element: <OnlyUnauthorized><AuthPage/></OnlyUnauthorized>,
                children: [
                    {
                        index: true,
                        element: <EnterEmail/>,
                    },
                    {
                        path: AUTH_WELCOME_PATH,
                        element: <EnterCurrentPassword/>,
                    },
                    {
                        path: AUTH_RESTORE_CONFIRM_PATH,
                        element: <ConfirmEmail/>,
                    },
                    {
                        path: AUTH_RESTORE_FINISH_PATH,
                        element: <EnterNewPassword/>,
                    },
                    {
                        path: AUTH_SIGNUP_CONFIRM_PATH,
                        element: <ConfirmEmail isSignUp/>,
                    },
                    {
                        path: AUTH_SIGNUP_FINISH_PATH,
                        element: <EnterNewPassword isSignUp/>,
                    },
                ],
            },
            {
                path: ACCOUNT_PATH,
                element: <AuthRequired><AccountPage/></AuthRequired>,
            },
            {
                path: '*',
                element: <ErrorPage/>,
            },
        ],
    },
]);

export default router;