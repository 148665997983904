import React, {FC} from 'react';
import FormField from '../FormField/FormField';
import {SelectMimicryProps} from './types';
import classNames from 'classnames';
import {IconDropdown} from '../../../assets/icons';

const SelectMimicry: FC<SelectMimicryProps> = ({children, placeholder, ...props}) => {
    const cl = classNames('Select', {
        'Select--empty': !children,
    });
    
    return (
        <FormField className={cl} after={<IconDropdown/>}>
            <div className={'select_el'}  {...props}>
                {children || placeholder}
            </div>
        </FormField>
    );
};

export default SelectMimicry;