import React, {FC, HTMLAttributes} from 'react';

import './FormBottomButtons.css';

const FormBottomButtons: FC<HTMLAttributes<HTMLDivElement>> = ({children}) => {
    return (
        <div className={'form__bottomButtons'}>
            {children}
        </div>
    );
};

export default FormBottomButtons;