import React, {FC} from 'react';
import {GroupProps} from './types';

import './Group.css';

const Group: FC<GroupProps> = ({children}) => {
    
    return (
        <section className={'Group'}>
            {children}
        </section>
    );
};

export default Group;