import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import {NavItemProps} from './types';

import './NavItem.css';

const NavItem: FC<NavItemProps> = ({href, children, icon: Icon}) => {
    return (
        <NavLink to={href} className={({isActive}) => isActive ? 'NavItem NavItem-active' : 'NavItem'}>
            <Icon className={'NavItem__icon'}/>
            {children}
        </NavLink>
    );
};

export default NavItem;