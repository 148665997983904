import React from 'react';
import {Body, Button} from '../../index';
import {IconLogoVK} from '../../../assets/icons';

import './FormSocialButtons.css';

const FormSocialButtons = () => {
    return (
        <>
            <div className={'formDescription'}>
                <div className={'formDescription__line'}/>
                <Body level={2}>или</Body>
                <div className={'formDescription__line'}/>
            </div>
            <Button className={'VkIdButton'} before={<IconLogoVK/>}>Войти через VK ID</Button>
        </>
    );
};

export default FormSocialButtons;