import React from 'react';
import {Outlet} from 'react-router-dom';

import {ModalRoot, PageHeader} from '../components';
import {SelectCityModalPage} from '../modals';

import {useModal} from '../hooks/useModal';

const PageWrapper = () => {
    const {activeModal, closeModal} = useModal();
    
    return (
        <>
            <PageHeader/>
            <div className={'PageLayout'}>
                <div className={'PageLayout__in'}>
                    <Outlet/>
                </div>
            </div>
            <ModalRoot id={'modal-root'} activeModal={activeModal} onClose={closeModal}>
                <SelectCityModalPage id={'selectCity'} closeModal={closeModal}/>
            </ModalRoot>
        </>
    );
};

export default PageWrapper;