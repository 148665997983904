import React, {FC} from 'react';
import {FormItemProps} from './types';
import {Body} from '../../index';

import './FormItem.css';
import classNames from 'classnames';

const FormItem: FC<FormItemProps> = ({
        children,
        label,
        message,
        status = 'default',
        className,
        ...props
    }) => {
        const cl = classNames(
            'FormItem',
            `FormItem--status-${status}`,
            className && className,
        );
        
        return (
            <div className={cl} {...props}>
                <label>
                    {label && <Body level={2} className={'FormItem__label'} Component={'h5'}>{label}</Body>}
                    {children}
                    {message && <Body level={2} className={'FormItem__message'}>{message}</Body>}
                </label>
            </div>
        );
    }
;

export default FormItem;