import React, {FC, FormEvent} from 'react';
import {useAuth} from '../../../pages/AuthPage';
import {Button, FormItem, Header, Input} from '../../index';
import {useNavigate} from 'react-router-dom';
import {useForm, useValidateForm} from '../../../hooks';
import {ACCOUNT_PATH} from '../../../router';

const EnterNewPassword: FC<{ isSignUp?: boolean }> = ({isSignUp}) => {
    const {
        status: passwordStatus,
        message: passwordMessage,
        setError: setPasswordError,
        setValid: setPasswordValid,
    } = useValidateForm();
    
    const {
        status: confirmedPasswordStatus,
        message: confirmedPasswordMessage,
        setError: setConfirmedPasswordError,
        setValid: setConfirmedPasswordValid,
    } = useValidateForm();
    
    const navigate = useNavigate();
    const {userData: {email}} = useAuth();
    const {formData: {password, confirmedPassword}, onInputChange: onPasswordChange} = useForm({
        password: '',
        confirmedPassword: '',
    });
    
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if (!password.trim() || !confirmedPassword.trim()) {
            !password.trim() && setPasswordError('Введите новый пароль');
            !confirmedPassword.trim() && setConfirmedPasswordError('Повторите новый пароль');
            return;
        }
        
        if (password.trim() !== confirmedPassword.trim()) {
            setConfirmedPasswordError('Пароли не совпадают');
            return;
        }
        
        navigate(ACCOUNT_PATH);
    };
    
    return (
        <>
            <Header subheader={'вы будете использовать его при следующем входе'}>
                {isSignUp ? 'Придумайте пароль' : 'Новый пароль'}
            </Header>
            <form onSubmit={onSubmit}>
                <FormItem hidden>
                    <Input
                        type={'email'}
                        name={'email'}
                        defaultValue={email}
                        autoComplete={'email'}
                    />
                </FormItem>
                <FormItem
                    label={'Пароль'}
                    status={passwordStatus}
                    message={passwordMessage}
                >
                    <Input
                        type={'password'}
                        name={'password'}
                        value={password}
                        onChange={onPasswordChange}
                        onClick={setPasswordValid}
                        autoComplete={'new-password'}
                        placeholder={'Новый пароль'}
                    />
                </FormItem>
                <FormItem
                    label={'Повторите пароль'}
                    status={confirmedPasswordStatus}
                    message={confirmedPasswordMessage}
                >
                    <Input
                        type={'password'}
                        name={'confirmedPassword'}
                        value={confirmedPassword}
                        onChange={onPasswordChange}
                        onClick={setConfirmedPasswordValid}
                        autoComplete={'new-password'}
                        placeholder={'Новый пароль'}
                    />
                </FormItem>
                <Button type={'submit'}>
                    {isSignUp ? 'Продолжить' : 'Сохранить новый пароль'}
                </Button>
            </form>
        </>
    );
};

export default EnterNewPassword;