import React from 'react';
import {Container, FormItem, Group, Header, Input, SelectMimicry} from '../components';
import {useForm, useModal} from '../hooks';

const AccountPage = () => {
    const {openModal} = useModal();
    const {formData: {firstName, city}, onInputChange, setFromData} = useForm({
        firstName: '',
        city: '',
    });
    
    const onCityChange = () => {
        openModal('selectCity');
    };
    
    return (
        <Container>
            <Group>
                <Header subheader={'чтобы начать пользоваться сервисом'}>
                    Расскажите о себе
                </Header>
                <form>
                    <FormItem label={'Имя'}>
                        <Input
                            required
                            name={'firstName'}
                            value={firstName}
                            onChange={onInputChange}
                            placeholder={'Введите ваше имя'}
                            autoComplete={'given-name'}
                        />
                    </FormItem>
                    <FormItem label={'Город'}>
                        <SelectMimicry
                            onClick={onCityChange}
                            placeholder={'Выберите город'}
                        >
                            {city}
                        </SelectMimicry>
                    </FormItem>
                </form>
            </Group>
        </Container>
    );
};

export default AccountPage;