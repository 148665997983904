import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {OnlyUnauthorizedProps} from './types';
import {useAppSelector} from '../../../hooks/redux';
import {ACCOUNT_PATH} from '../../../router';

const OnlyUnauthorized: FC<OnlyUnauthorizedProps> = ({children}) => {
    const {data: userData} = useAppSelector(state => state.user);
    const isAuth = !!userData?.email;
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if (isAuth) navigate(ACCOUNT_PATH);
    }, [isAuth, navigate]);
    
    return children;
};

export default OnlyUnauthorized;