import React, {FC} from 'react';
import classNames from 'classnames';
import Body from '../Typography/Body/Body';

import {ButtonProps, ButtonTextProps} from './types';
import './Button.css';

const ButtonText: FC<ButtonTextProps> = ({size, children}) => {
    switch (size) {
        case 's':
            return <Body level={2}>{children}</Body>;
        case 'm':
        case 'l':
            return <Body>{children}</Body>;
        default:
            return <Body>{children}</Body>;
    }
};

const Button: FC<ButtonProps> = ({
    children,
    mode = 'primary',
    size = 'm',
    appearance = 'accent',
    before,
    after,
    className,
    type = 'button',
    stretched = false,
    ...props
}) => {
    const cl = classNames(
        'Button',
        `Button__mode-${mode}`,
        `Button__size-${size}`,
        `Button__appearance-${appearance}`,
        className && className,
        {
            'Button--stretched': stretched,
        },
    );
    
    return (
        <button type={type} className={cl} {...props}>
            {before}
            <ButtonText size={size}>{children}</ButtonText>
            {after}
        </button>
    );
};

export default Button;