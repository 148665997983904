import React, {FC} from 'react';
import classNames from 'classnames';

import {ModalPageProps} from './types';

import './ModalPage.css';


const ModalPage: FC<ModalPageProps> = ({children, id, header, footer, fullScreen = false}) => {
    const cl = classNames('ModalPage', {
        'ModalPage--fullScreen': fullScreen,
    });
    
    return (
        <div id={id} className={'ModalPage__wrapper'}>
            <div className={cl} onClick={(e) => e.stopPropagation()}>
                <div className={'ModalPage__in'}>
                    {header}
                    <div className={'ModalPage__content'}>
                        <div className={'ModalPage__content_in'}>
                            {children}
                        </div>
                    </div>
                    {footer && <div className={'ModalPage__footer'}>{footer}</div>}
                </div>
            </div>
        </div>
    );
};

export default ModalPage;